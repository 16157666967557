/* ================================================== */
/* BASICS */
/* ================================================== */

* {
  box-sizing: border-box;
}

body {
  background-color: #222222;
  font-family: 'Share Tech Mono', monospace;
  color: #77ee33;
  text-align: center;
}

.root {
  padding: 5vmin;
  min-height: 100vh;
  font-size: calc(10px + 3vmin);
  white-space: pre-line;
}

.center {
  max-width: 100vmin;
  margin: auto;
}

/* ================================================== */
/* SECTION */
/* ================================================== */

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* ================================================== */
/* VISUAL IMAGE */
/* ================================================== */

.visualimage {
  z-index: -100;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  opacity: 0.1;
}

.sourceexternal1 {
  filter: grayscale(1.0) blur(0.4vmin);
}

.sourceexternal2 {
  filter: grayscale(1.0);
}

.sourceexternal3 {
  filter: grayscale(0.6);
}

.sourceinternal {
  filter: grayscale(0.6);
}

/* ================================================== */
/* VISUAL TEXT */
/* ================================================== */

.visualtextsection {
  @extend .section;
  min-height: 15vh;
  p {
    padding: 0;
    margin: 0;
  }
}

/* ================================================== */
/* NOTE */
/* ================================================== */

.notesection {
  @extend .section;
  min-height: 15vh;
  p {
    padding: 0;
    margin: 0;
    font-style: italic;
  }
}

/* ================================================== */
/* OPTIONS */
/* ================================================== */

.optionssection {
  @extend .section;
  min-height: 40vh;
  user-select: none;
}

.options {
  padding: 0;
  margin: 0;
  min-width: 50vmin;
}

.option {
  display: flex;
  min-width: 70%;
  flex-direction: row;
  cursor: pointer;
}

.option:hover {
  background-color: #77ee331f;
}

.option.clicked {
  background-color: #77ee3342;
}

.optionsub {
  padding: 0 3vmin;
}

.optionkey {
  @extend .optionsub;
  width: 5vmin;
  text-align: right;
}

.optionname {
  @extend .optionsub;
  text-align: left;
}

/* ================================================== */
/* STATS */
/* ================================================== */

.statssection {
  @extend .section;
  min-height: 20vh;
}

.stat {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.statsub {
  width: 50%;
  padding: 0 1vmin;
}

.statamount {
  @extend .statsub;
  text-align: right;
}

.statname {
  @extend .statsub;
  text-align: left;
}
